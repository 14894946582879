import React, { useState } from 'react';
import './Donation.scss';
import { firestore } from './../../firebase';
import { getFirestore, collection, addDoc } from "firebase/firestore";


import backgroundMedia from './../../assets/hg-2024-bg.jpg';
import thirdRailLogo from './../../assets/3rc-logo.png';
import exitIcon from './../../assets/exit-icon.png';
import wrappingPaperDonation from './../../assets/wrapping-paper-donation.png';
import wrappingPaperStrip from './../../assets/wrapping-paper-strip.png';
import bbbs from './../../assets/bbbs.png';
import bookSpring from './../../assets/book-spring.png';
import casa from './../../assets/casa.png';
import bbbsImage from './../../assets/bbbs-image.png';
import bookSpringImage from './../../assets/book-spring-image.png';
import casaImage from './../../assets/casa-image.png';

const charities = [
  {
    name: 'BookSpring',
    logo: bookSpring,
    image: bookSpringImage,
    summary: 'BookSpring provides reading experiences, tools, and books to children and their families to help build literacy skills and achieve future educational success.'
  },
  {
    name: 'Big Brothers Big Sisters',
    logo: bbbs,
    image: bbbsImage,
    summary: 'Big Brothers Big Sisters makes meaningful, monitored matches between adult volunteers and children, developing positive relationships that have a direct and lasting effect on the lives of young people.'
  },
  {
    name: 'CASA',
    logo: casa,
    image: casaImage,
    summary: 'CASA advocates for children who have experienced abuse or neglect by training volunteers to advocate for them in courts, in schools and in our community.'
  }
];

const Modal = ({ charity, onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [size, setSize] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handleKeyDown = (e) => {
    if (e.key === 'Escape' && currentPage === 1) {
      onClose();
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  const handleNextPage = () => {
    setCurrentPage(2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collection(firestore, 'holiday-gift-2024'), {
        name: name,
        email: email,
        size: size,
        donation: charity.name,
        timestamp: new Date()
      });

      setIsSubmitted(true);

      setTimeout(() => {
        onClose();
      }, 3000);
    } catch (error) {
      console.error("Error submitting donation:", error);
    }
  };

  return (
    <div className={`modal-overlay ${charity.name.toLowerCase().replace(/[^a-zA-Z0-9\s-]/g, '').replace(/\s+/g, '-')}`} onClick={currentPage === 1 ? onClose : undefined}>
      <div className="modal-content" onClick={e => e.stopPropagation()} onKeyDown={handleKeyDown} tabIndex="0" style={{ backgroundImage: `url(${backgroundMedia})` }}>
        {currentPage === 1 && (
          <button
            className="close-button"
            onClick={onClose}
            aria-label="Close modal"
            tabIndex="0"
          >
            <img src={exitIcon} alt="Exit icon" />
          </button>
        )}
        {(currentPage === 2 && !isSubmitted) && (
          <button
            className="close-button"
            onClick={(e) => {
              handleSubmit(e);
              onClose();
            }}
            aria-label="Close modal and submit form"
            tabIndex="0"
          >
            <img src={exitIcon} alt="Exit icon" />
          </button>
        )}
        {(currentPage === 2 && isSubmitted) && (
          <button
            className="close-button"
            onClick={onClose}
            aria-label="Close modal"
            tabIndex="0"
          >
            <img src={exitIcon} alt="Exit icon" />
          </button>
        )}
        {!isSubmitted ? (
          currentPage === 1 ? (
            <>
              <div className="charity-info-container">
                <div className="charity-info">
                  <h3 className="charity-name">{charity.name}</h3>
                  <p className="charity-summary">{charity.summary}</p>
                </div>
                <div className="charity-logo">
                  <img src={charity.logo} alt={`${charity.name} logo`} />
                </div>
              </div>
              <div className="button-container">
                <button
                  className="select-charity-button"
                  onClick={handleNextPage}
                  aria-label="Select this charity"
                  tabIndex="0"
                >
                  Select this charity
                </button>
              </div>
            </>
          ) : (
              <form onSubmit={handleSubmit}>
                <div className="logo-container">
                  <img src={thirdRailLogo} alt="Third Rail Creative logo" />
                </div>
                <h3>Thank you for your donation!</h3>
                <br></br>
                <br></br>
                <p>P.S. Want your own 3RC Hoodie?<br></br><br></br> It's a brand-new design for this year—and it's our gift to you! Just fill out the form below to claim yours.</p>
              <div className="form-group">
                <label htmlFor="name">Enter your name or leave blank</label>
                <input
                  type="name"
                  id="name"
                  placeholder='Name'
                  value={name}
                  onChange={handleNameChange}
                  aria-label="Name of participant"
                  tabIndex="0"
                />
                <label htmlFor="email">Enter your email if you would like to receive a 3RC hoody</label>
                <input
                  type="email"
                  id="email"
                placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                  aria-label="Email for hoody"
                  tabIndex="1"
                />
                <label htmlFor="size">Select your hoodie size</label>
                <select
                  id="size"
                  value={size}
                  onChange={handleSizeChange}
                  aria-label="Hoodie size"
                  tabIndex="2"
                >
                  <option value="">Select a size</option>
                  <option value="XS">XS</option>
                  <option value="S">S</option>
                  <option value="M">M</option>
                  <option value="L">L</option>
                  <option value="XL">XL</option>
                  <option value="2XL">2XL</option>
                  <option value="3XL">3XL</option>
                </select>
                </div>
                <div className="button-container">
                  <button
                    type="submit"
                    className="submit-button"
                    aria-label="Submit selection"
                    tabIndex="3"
                  >
                    Submit selection
                    </button>
                    <button
                    type="submit"
                    className="submit-button decline-hoodie"
                    aria-label="Submit selection"
                    tabIndex="3"
                  >
                    No thank you, just the donation
                  </button>
                </div>
            </form>
          )
        ) : (
            <div className="thank-you-message">
              <div className="logo-container">
                <img src={thirdRailLogo} alt="Third Rail Creative logo" />
              </div>
              <h3>Thank You!</h3>
              <p>We appreciate your selection. Your donation will help make a difference.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default function Donation() {
  const [selectedCharity, setSelectedCharity] = useState(null);

  const handleCharityClick = (charity) => {
    setSelectedCharity(charity);
  };

  const handleCloseModal = () => {
    setSelectedCharity(null);
  };

  return (
    <section className="donation-2024" id="donation2024" style={{ backgroundImage: `url(${wrappingPaperDonation})` }}>
      <div className="container">
        <div className="content-container" style={{ backgroundImage: `url(${backgroundMedia})` }}>
          <div className="donation-note">
            <h2>After looking back, we're paying it forward.</h2>
            <p>Thank you for being part of our 2024. We invite you to choose a charity for Third Rail Creative to donate to on your behalf.<br></br><br></br> Here are a few that are near and dear to our hearts. Click a charity to learn more.</p>
          </div>
          <div className="charities">
            {charities.map((charity, index) => (
              <div
                className={`charity ${charity.name.toLowerCase().replace(/[^a-zA-Z0-9\s-]/g, '').replace(/\s+/g, '-')}`}
                key={index}
                onClick={() => handleCharityClick(charity)}
                onKeyDown={(e) => e.key === 'Enter' && handleCharityClick(charity)}
                tabIndex="0"
                role="button"
                aria-label={`Learn more about ${charity.name}`}
              >
                <div className="charity-logo">
                  <img src={charity.image} className="logo" alt={`${charity.name} logo`} />
                </div>
                <h3 className="charity-name">{charity.name}</h3>
                <p>Learn more {'>'}</p>
              </div>
              ))}
          </div>
        </div>
      </div>
      {selectedCharity && (
        <Modal charity={selectedCharity} onClose={handleCloseModal} />
      )}
      <div className="wrapping-paper-strip top">
        <img src={wrappingPaperStrip} alt="Wrapping paper strip" />
      </div>
      <div className="wrapping-paper-strip bottom">
        <img src={wrappingPaperStrip} alt="Wrapping paper strip" />
      </div>
    </section>
  );
};
