import React, { useEffect, useRef, useState } from 'react';
import './Experiences.scss';

import starRed from './../../assets/star-red.png';
import starBlue from './../../assets/star-blue.png';
import circleYellow from './../../assets/circle-yellow.png';
import burstYellow from './../../assets/burst-yellow.png';
import flowerYellow from './../../assets/flower-yellow.png';

import placeholder from './../../assets/placeholder.png';
import ArtBarn from './../../assets/art-barn.png';
import IceCreamMaking from './../../assets/ice-cream-making.png';
import EscapeRoom from './../../assets/escape-room.png';
import PaintByNumbers from './../../assets/paint-by-number.png';
import VisionBoards from './../../assets/vision-boards.png';
import WildflowerCenter from './../../assets/lbj-wildflower-center.png';


export default function Experiences() {
  const examplesRef = useRef(null);
  const [lightboxImage, setLightboxImage] = useState(null);
  const [isLightboxVisible, setIsLightboxVisible] = useState(false);


  const handleImageClick = (image) => {
    setLightboxImage(image);
    setIsLightboxVisible(true);
  };

  const handleCloseLightbox = () => {
    setIsLightboxVisible(false);
    setLightboxImage(null);
  };

  return (
    <section className="experiences">
      <div className="container">
        <div className="description">
          <h2>
            ...and shared experiences.
          </h2>
          <p>
            With escape rooms, online survival games, and even an Emo Bowling Night, we found new ways to connect in person and online.
          </p>
        </div>
        <div className="examples" ref={examplesRef}>
          <div
            className="example art-barn"
            // onClick={() => handleImageClick(ArtBarn)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(ArtBarn)}
            // tabIndex="0"
            // role="button"
            aria-label="View Art Barn Paint Party image">
            <img src={ArtBarn} alt="Art Barn Paint Party" />
          </div>
          <div
            className="example ice-cream-making"
            // onClick={() => handleImageClick(IceCreamMaking)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(IceCreamMaking)}
            // tabIndex="0"
            // role="button"
            aria-label="View Ice Cream Making image">
            <img src={IceCreamMaking} alt="Virtual Ice Cream Making Workshop" />
          </div>
          <div
            className="example escape-room"
            // onClick={() => handleImageClick(EscapeRoom)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(EscapeRoom)}
            // tabIndex="0"
            // role="button"
            aria-label="View Escape Room image">
            <img src={EscapeRoom} alt="Virtual Escape Room" />
          </div>
          <div
            className="example paint-by-number"
            // onClick={() => handleImageClick(PaintByNumbers)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(PaintByNumbers)}
            // tabIndex="0"
            // role="button"
            aria-label="View Paint by Number image">
            <img src={PaintByNumbers} alt="Paint by Number" />
          </div>
          <div
            className="example vision-boards"
            // onClick={() => handleImageClick(VisionBoards)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(VisionBoards)}
            // tabIndex="0"
            // role="button"
            aria-label="View vision boards image">
            <img src={VisionBoards} alt="Vision Boards" />
          </div>
          <div
            className="example wildflower-center"
            // onClick={() => handleImageClick(VisionBoards)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(VisionBoards)}
            // tabIndex="0"
            // role="button"
            aria-label="View wildflower center image">
            <img src={WildflowerCenter} alt="Wildflower Center" />
          </div>
        </div>
      </div>
      <div className="background-media">
        <img src={flowerYellow} className="flower yellow" alt="flower yellow" />
        <img src={starBlue} className="star blue" alt="star blue" />
        <img src={starRed} className="star red" alt="star red" />
        <div className="circle-burst yellow one">
          <img src={circleYellow} className="circle" alt="circle yellow" />
          <img src={burstYellow} className="burst" alt="burst yellow" />
        </div>
      </div>
      {isLightboxVisible && (
        <div className={`lightbox ${isLightboxVisible ? 'visible' : ''}`} onClick={handleCloseLightbox} onKeyDown={(e) => e.key === 'Escape' && handleCloseLightbox()} tabIndex="0" role="button">
          <button className="close-button" onClick={handleCloseLightbox} aria-label="Close lightbox">×</button>
          <img src={lightboxImage} alt="Lightbox" />
        </div>
      )}
    </section>
  );
};
