import React, { useEffect, useRef, useState } from 'react';
import './Busy.scss';

import starGreen from './../../assets/star-green.png';
import starRed from './../../assets/star-red.png';
import flowerRed from './../../assets/flower-red.png';

import speechBubble from './../../assets/speech-bubble.png';
import files from './../../assets/files.png';
import puzzle from './../../assets/puzzle.png';

export default function Busy() {
  const examplesRef = useRef(null);
  const descriptionRef = useRef(null);
  const [messageCount, setMessageCount] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const [projectCount, setProjectCount] = useState(0);
  const hasAnimatedRef = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
            if (entry.target.classList.contains('examples') && !hasAnimatedRef.current) {
            // Start counting animations only if not animated before
            hasAnimatedRef.current = true;

            const animateCount = (start, end, setter, duration) => {
              const startTime = Date.now();
              const updateCount = () => {
                const now = Date.now();
                const progress = Math.min((now - startTime) / duration, 1);
                const currentCount = Math.floor(progress * (end - start) + start);
                setter(currentCount);
                if (progress < 1) {
                  requestAnimationFrame(updateCount);
                }
              };
              requestAnimationFrame(updateCount);
            };

            animateCount(0, 108665, setMessageCount, 2000);
            animateCount(0, 7861, setFileCount, 2000);
            animateCount(0, 158, setProjectCount, 2000);
          } else {
            entry.target.style.transform = 'translateY(-15px)';
            entry.target.style.opacity = '1';
          }
        }
      });
    }, { threshold: 0.1 });

    const descriptionChildren = document.querySelectorAll('.description > *');
    const exampleItems = document.querySelectorAll('.example');

    [...descriptionChildren, ...exampleItems, examplesRef.current].forEach(el => {
      el.style.transition = 'transform 0.8s ease-out, opacity 0.8s ease-out';
      el.style.transform = 'translateY(-25px)';
      el.style.opacity = '1';
      observer.observe(el);
    });
  }, []);

  return (
    <section className="busy">
      <div className="container">
        <div className="description" ref={descriptionRef}>
          <h2>
            <img src={starRed} className="star red" alt="star red" />
            We were busy, busy, busy.
          </h2>
          <p>
            Collaboration and communication are big around here.
          </p>
        </div>
        <div className="examples" ref={examplesRef}>
          <div className="example files">
            <img src={files} alt="Files icon" />
            <div className="number">{fileCount.toLocaleString()}</div>
            <div className="label">Files uploaded</div>
          </div>
          <div className="example projects">
            <img src={puzzle} alt="Puzzle icon" />
            <div className="number">{projectCount.toLocaleString()}</div>
            <div className="label">New projects</div>
          </div>
          <div className="example messages">
            <img src={speechBubble} alt="Speech bubble icon" />
            <div className="number">{messageCount.toLocaleString()}</div>
            <div className="label">Messages sent</div>
          </div>
        </div>
      </div>
      <div className="background-media">
        <img src={flowerRed} className="flower red" alt="flower red" />
      </div>
    </section>
  );
};
