import React from 'react';
import { Helmet } from 'react-helmet';
import './index.scss';

import backgroundMedia from './assets/hg-2024-bg.jpg';

import Intro from './components/Intro/Intro';
import Reflection from './components/Reflection/Reflection';
import Growth from './components/Growth/Growth';
import Experiences from './components/Experiences/Experiences';
import Busy from './components/Busy/Busy';
import WorkHabits from './components/WorkHabits/WorkHabits';
import Explore from './components/Explore/Explore';
import NewSkills from './components/NewSkills/NewSkills';
import LifeChangingExperiences from './components/LifeChangingExperiences/LifeChangingExperiences';
import Donation from './components/Donation/Donation';
export default function HolidayGift2024() {
  return (
    <>
      <Helmet bodyAttributes={{ class: 'holiday-gift-2024' }}>
        <title>Third Rail Creative</title>
        <meta name="description" content="Helmet application" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
      </Helmet>
      <div className="holiday-gift-2024-content">
        <Intro />
        <Reflection />
        <Growth />
        <Experiences />
        <Busy />
        {/* <WorkHabits /> */}
        <Explore />
        <NewSkills />
        <LifeChangingExperiences />
        <Donation />
      </div>
      <div className="background-texture">
        <img src={backgroundMedia} alt="background media" />
      </div>
    </>
  );
};
