import React, { useEffect, useRef, useState } from 'react';
import './Growth.scss';

import starGreen from './../../assets/star-green.png';

import firstRoundLogo from './../../assets/first-round.png';
import aigaLogo from './../../assets/aiga.png';
import ultraSpeakingLogo from './../../assets/ultra-speaking.png';
import sxswLogo from './../../assets/sxsw.png';
import womensConferenceLogo from './../../assets/womens-conference.png';
import marketingBrewLogo from './../../assets/marketing-brew.png';

import firstRound from './../../assets/conference-first-round.png';
import aiga from './../../assets/conference-aiga.png';
import ultraSpeaking from './../../assets/conference-ultraspeaking.png';
import sxsw from './../../assets/conference-sxsw.png';
import womensConference from './../../assets/conference-womens.png';
import marketingBrew from './../../assets/conference-marketing-brew.png';

import placeholder from './../../assets/placeholder.png';

export default function Growth() {
  const examplesRef = useRef(null);
  // const descriptionRef = useRef(null);
  const [lightboxImage, setLightboxImage] = useState(null);
  const [isLightboxVisible, setIsLightboxVisible] = useState(false);


  const handleImageClick = (imageSrc) => {
    setLightboxImage(imageSrc);
    setTimeout(() => setIsLightboxVisible(true), 50);
  };

  const handleCloseLightbox = () => {
    setIsLightboxVisible(false);
    setTimeout(() => setLightboxImage(null), 300); // Wait for fade out animation
  };

  return (
    <section className="growth">
      <div className="container">
        <div className="description">
          <h2>
            <img src={starGreen} className="star green" alt="star green" />
            2024 was a year of shared growth…
          </h2>
          <p>
            From conferences to workshops, this year was about leveling up together.
          </p>
        </div>
        <div className="examples" ref={examplesRef}>
          <div className="example first-round">
            <div className="group-container">
              <img src={firstRoundLogo} className="event-icon" alt="first round" />
              <div
                className="event-image-container"
                // onClick={() => handleImageClick(firstRound)}
                // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(firstRound)}
                // role="button"
                tabIndex={0}
                aria-label="View first round image"
              >
                <img src={firstRound} alt="first round" />
              </div>
            </div>
            <div className="label">
              <h4>First Round</h4>
            </div>
          </div>
          <div className="example womens-conference">
            <div className="group-container">
              <img src={womensConferenceLogo} className="event-icon" alt="womens conference" />
              <div
                className="event-image-container"
                // onClick={() => handleImageClick(womensConference)}
                // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(womensConference)}
                // role="button"
                tabIndex={0}
                aria-label="View women's conference image"
              >
                <img src={womensConference} alt="women's conference event" />
              </div>
            </div>
            <div className="label">
              <h4>Women's Conference</h4>
            </div>
          </div>
          <div className="example sxsw">
            <div className="group-container">
              <img src={sxswLogo} className="event-icon" alt="sxsw" />
              <div
                className="event-image-container"
                // onClick={() => handleImageClick(sxsw)}
                // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(sxsw)}
                // role="button"
                tabIndex={0}
                aria-label="View SXSW image"
              >
                <img src={sxsw} alt="SXSW event" />
              </div>
            </div>
            <div className="label">
              <h4>SXSW</h4>
            </div>
          </div>
          <div className="example ultra-speaking">
            <div className="group-container">
              <img src={ultraSpeakingLogo} className="event-icon" alt="ultra speaking" />
              <div
                className="event-image-container"
                // onClick={() => handleImageClick(ultraSpeaking)}
                // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(ultraSpeaking)}
                // role="button"
                tabIndex={0}
                aria-label="View ultra speaking image"
              >
                <img src={ultraSpeaking} alt="ultra speaking event" />
              </div>
            </div>
            <div className="label">
              <h4>Ultraspeaking</h4>
            </div>
          </div>
          <div className="example marketing-brew">
            <div className="group-container">
              <img src={marketingBrewLogo} className="event-icon" alt="marketing brew" />
              <div
                className="event-image-container"
                // onClick={() => handleImageClick(marketingBrew)}
                // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(marketingBrew)}
                // role="button"
                tabIndex={0}
                aria-label="View marketing brew image"
              >
                <img src={marketingBrew} alt="marketing brew event" />
              </div>
            </div>
            <div className="label">
              <h4>Marketing Brew</h4>
            </div>
          </div>
          <div className="example aiga">
            <div className="group-container">
              <img src={aigaLogo} className="event-icon" alt="aiga" />
              <div
                className="event-image-container"
                // onClick={() => handleImageClick(aiga)}
                // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(aiga)}
                // role="button"
                tabIndex={0}
                aria-label="View AIGA image"
              >
                <img src={aiga} alt="AIGA event" />
              </div>
            </div>
            <div className="label">
              <h4>AIGA</h4>
            </div>
          </div>
        </div>
      </div>

      {lightboxImage && (
        <div
          className="lightbox"
          onClick={handleCloseLightbox}
          onKeyDown={(e) => e.key === 'Escape' && handleCloseLightbox()}
          role="button"
          tabIndex={0}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: `rgba(0, 0, 0, ${isLightboxVisible ? '0.8' : '0'})`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            cursor: 'pointer',
            opacity: isLightboxVisible ? 1 : 0,
            transition: 'opacity 0.3s ease-in-out, background-color 0.3s ease-in-out'
          }}
        >
          <button
            onClick={handleCloseLightbox}
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              background: 'none',
              border: 'none',
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              padding: '10px',
              zIndex: 1001,
              opacity: isLightboxVisible ? 1 : 0,
              transform: `translateY(${isLightboxVisible ? '0' : '-20px'})`,
              transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out'
            }}
            aria-label="Close lightbox"
          >
            ✕
          </button>
          <img
            src={lightboxImage}
            alt="Enlarged view"
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              objectFit: 'contain',
              opacity: isLightboxVisible ? 1 : 0,
              transform: `scale(${isLightboxVisible ? '1' : '0.95'})`,
              transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out'
            }}
          />
        </div>
      )}
    </section>
  );
};
