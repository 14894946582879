import React from 'react';
import './Reflection.scss';

import flowerRed from './../../assets/flower-red.png';
import starYellow from './../../assets/star-yellow.png';
import circleBlue from './../../assets/circle-blue.png';
import burstBlue from './../../assets/burst-blue.png';


export default function Reflection() {
  const handleScrollToDonation = () => {
    const donationSection = document.getElementById('donation2024');
    donationSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className="reflection">
      <div className="container">
        <div className="reflection-note">
          <h2>We're taking a moment to reflect on 2024's highlights &mdash;</h2>
          <br></br><br></br><br></br>
          <p>
            The projects, the wins, and the clients like you who made it possible.<br></br><br></br><br></br>At the end, we invite you to pick a charity for 3RC to support on your behalf.<br></br><br></br><br></br>
            <button
              onClick={handleScrollToDonation}
              onKeyDown={(e) => e.key === 'Enter' && handleScrollToDonation()}
              tabIndex={0}
              aria-label="Skip to donation section"
            >
              Skip to the gift {'>'}
            </button>
          </p>
        </div>
      </div>
      <div className="background-media">
        <img src={flowerRed} className="flower red" alt="flower red" />
        <img src={starYellow} className="star yellow one" alt="star yellow" />
        <img src={starYellow} className="star yellow two" alt="star yellow" />
        <div className="circle-burst blue">
          <img src={circleBlue} className="circle blue" alt="circle blue" />
          <img src={burstBlue} className="burst blue" alt="burst blue" />
        </div>
      </div>
    </section>
  );
};
