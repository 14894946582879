import React, { useEffect, useRef, useState } from 'react';
import './Explore.scss';

import circleGreen from './../../assets/circle-green.png';
import burstGreen from './../../assets/burst-green.png';
import circleYellow from './../../assets/circle-yellow.png';
import burstYellow from './../../assets/burst-yellow.png';
import world from './../../assets/world.png';
import luggage from './../../assets/luggage.png';
import car from './../../assets/car.png';
import passport from './../../assets/passport.png';

import explorationCara from './../../assets/exploration-cara.png';
import AbbeyBoston from './../../assets/abbey-boston.png';
import AnnieBocaGrande from './../../assets/annie-boca-grande.png';
import AnnieCayman from './../../assets/annie-cayman.png';
import CaraHawaii1 from './../../assets/cara-hawaii-1.png';
import CaraHawaii2 from './../../assets/cara-hawaii-2.png';
import HannaBrighton from './../../assets/hanna-brighton.png';
import HannaLondon from './../../assets/hanna-london.png';
import JillPigeonForge from './../../assets/jill-pigeon-forge.png';
import JillPadre from './../../assets/jill-padre.png';
import LaurenEngland from './../../assets/lauren-england.png';
import MarkIceland from './../../assets/mark-iceland.png';
import MarkMichigan from './../../assets/mark-michigan.png';
import NguyenChicago from './../../assets/nguyen-chicago.png';
import NguyenPortland from './../../assets/nguyen-portland.png';

export default function Explore() {
  const examplesRef = useRef(null);
  const descriptionRef = useRef(null);
  const intervalIdRef = useRef(null);
  const [lightboxImage, setLightboxImage] = useState(null);
  const [isLightboxVisible, setIsLightboxVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.style.transform = 'translateY(-15px)';
          entry.target.style.opacity = '1';
        }
      });
    }, { threshold: 0.1 });

    const descriptionChildren = document.querySelectorAll('.description > *');
    const exampleItems = document.querySelectorAll('.example');

    [...descriptionChildren, ...exampleItems].forEach(el => {
      el.style.transition = 'transform 0.8s ease-out, opacity 0.8s ease-out';
      el.style.transform = 'translateY(-25px)';
      el.style.opacity = '0';
      observer.observe(el);
    });

    // Initial z-index setup
    const examples = document.querySelectorAll('.explore .examples .example');
    examples.forEach((example, index) => {
      example.style.zIndex = 14 - index;
      example.style.transition = 'transform 0.5s ease-in-out';
      example.style.transform = 'translateX(0)';
    });

    // Function to update z-indexes with animation
    const updateZIndexes = async () => {
      const examples = document.querySelectorAll('.examples .example');
      let topZIndexElement = null;
      let highestZIndex = 0;

      // Find element with highest z-index
      examples.forEach(example => {
        const zIndex = parseInt(example.style.zIndex);
        if (zIndex > highestZIndex) {
          highestZIndex = zIndex;
          topZIndexElement = example;
        }
      });
      if (topZIndexElement) {
        // Animate out
        topZIndexElement.style.transform = 'translateX(350px) rotate(10deg)';

        // Wait for animation
        await new Promise(resolve => setTimeout(resolve, 500));

        // Update z-indexes
        examples.forEach(example => {
          const currentZIndex = parseInt(example.style.zIndex);
          if (currentZIndex === 14) {
            example.style.zIndex = 1;
          } else {
            example.style.zIndex = currentZIndex + 1;
          }
        });

        // Reset transform
        topZIndexElement.style.transform = 'translateX(-350px) rotate(0deg)';

        // Force browser reflow
        void topZIndexElement.offsetWidth;

        // Animate back
        requestAnimationFrame(() => {
          topZIndexElement.style.transform = 'translateX(0)';
        });
      }
    };

    // Trigger first update immediately
    updateZIndexes();

    // Start the interval with a slight delay to avoid double-transition
    setTimeout(() => {
      intervalIdRef.current = setInterval(updateZIndexes, 3000);
    }, 100);

    // Create observer for examples section to stop animation when out of view
    const zIndexObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting && intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = null;
        } else if (entry.isIntersecting && !intervalIdRef.current) {
          updateZIndexes(); // Immediate update when coming back into view
          intervalIdRef.current = setInterval(updateZIndexes, 3000);
        }
      });
    }, { threshold: 0.1 });

    // Observe the examples section
    if (examplesRef.current) {
      zIndexObserver.observe(examplesRef.current);
    }

    // Cleanup
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
      zIndexObserver.disconnect();
      observer.disconnect();
    };
  }, []);

  const handleImageClick = (imageSrc) => {
    setLightboxImage(imageSrc);
    setTimeout(() => setIsLightboxVisible(true), 50);
  };

  const handleCloseLightbox = () => {
    setIsLightboxVisible(false);
    setTimeout(() => setLightboxImage(null), 300);
  };

  return (
    <section className="explore">
      <div className="container">
        <div className="description" ref={descriptionRef}>
          <h2>
            <img src={world} className="world" alt="world" />
            We explored the world...
          </h2>
          <p>
            From the Golden Circle of Iceland to the shores of Hawaii, our team's vacations could make Carmen Sandiego jealous.
          </p>
          <div className="trip-stats">
            <div className="stat adventures">
              <img src={luggage} className="luggage" alt="luggage" />
              <span className="number">23</span>
              <span className="label">Adventures</span>
            </div>
            <div className="stat states">
              <img src={car} className="car" alt="car" />
              <span className="number">16</span>
              <span className="label">States</span>
            </div>
            <div className="stat countries">
              <img src={passport} className="passport" alt="passport" />
              <span className="number">7</span>
              <span className="label">Countries</span>
            </div>
          </div>
        </div>
        <div className="examples" ref={examplesRef}>
        <div
            className="example abbey-boston"
            // onClick={() => handleImageClick(AbbeyBoston)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(AbbeyBoston)}
            // role="button"
            // tabIndex={0}
            aria-label="View Abbey in Boston"
          >
            <img src={AbbeyBoston} alt="Abbey in Boston" />
          </div>
          <div
            className="example annie-boca-grande"
            // onClick={() => handleImageClick(AnnieBocaGrande)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(AnnieBocaGrande)}
            // role="button"
            // tabIndex={0}
            aria-label="View Annie in Boca Grande"
          >
            <img src={AnnieBocaGrande} alt="Annie in Boca Grande" />
          </div>
          <div
            className="example annie-cayman"
            // onClick={() => handleImageClick(AnnieCayman)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(AnnieCayman)}
            // role="button"
            // tabIndex={0}
            aria-label="View Annie in Grand Cayman"
          >
            <img src={AnnieCayman} alt="Annie in Grand Cayman" />
          </div>
          <div
            className="example cara-hawaii-1"
            // onClick={() => handleImageClick(CaraHawaii1)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(CaraHawaii1)}
            // role="button"
            // tabIndex={0}
            aria-label="View Cara in Hawaii"
          >
            <img src={CaraHawaii1} alt="Cara in Hawaii" />
          </div>
          <div
            className="example cara-hawaii-2"
            // onClick={() => handleImageClick(CaraHawaii2)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(CaraHawaii2)}
            // role="button"
            // tabIndex={0}
            aria-label="View Cara in Hawaii"
          >
            <img src={CaraHawaii2} alt="Cara in Hawaii" />
          </div>
          <div
            className="example hanna-brighton"
            // onClick={() => handleImageClick(HannaBrighton)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(HannaBrighton)}
            // role="button"
            // tabIndex={0}
            aria-label="View Hanna in Brighton"
          >
            <img src={HannaBrighton} alt="Hanna in Brighton" />
          </div>
          <div
            className="example hanna-london"
            // onClick={() => handleImageClick(HannaLondon)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(HannaLondon)}
            // role="button"
            // tabIndex={0}
            aria-label="View Hanna in London"
          >
            <img src={HannaLondon} alt="Hanna in London" />
          </div>
          <div
            className="example jill-pigeon-forge"
            // onClick={() => handleImageClick(JillPigeonForge)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(JillPigeonForge)}
            // role="button"
            // tabIndex={0}
            aria-label="View sixth exploration image"
          >
            <img src={JillPigeonForge} alt="Jill in Pigeon Forge" />
          </div>
          <div
            className="example jill-padre"
            // onClick={() => handleImageClick(JillPadre)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(JillPadre)}
            // role="button"
            // tabIndex={0}
            aria-label="View Jill in South Padre"
          >
            <img src={JillPadre} alt="Jill in South Padre" />
          </div>
          <div
            className="example lauren-england"
            // onClick={() => handleImageClick(LaurenEngland)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(LaurenEngland)}
            // role="button"
            // tabIndex={0}
            aria-label="View Lauren in England"
          >
            <img src={LaurenEngland} alt="Lauren in England" />
          </div>
          <div
            className="example mark-iceland"
            // onClick={() => handleImageClick(MarkIceland)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(MarkIceland)}
            // role="button"
            // tabIndex={0}
            aria-label="View Mark in Iceland"
          >
            <img src={MarkIceland} alt="Mark in Iceland" />
          </div>
          <div
            className="example mark-michigan"
            // onClick={() => handleImageClick(MarkMichigan)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(MarkMichigan)}
            // role="button"
            // tabIndex={0}
            aria-label="View Mark in Michigan"
          >
            <img src={MarkMichigan} alt="Mark in Michigan" />
          </div>
          <div
            className="example Nguyen in Chicago"
            // onClick={() => handleImageClick(NguyenChicago)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(NguyenChicago)}
            // role="button"
            // tabIndex={0}
            aria-label="View Nguyen in Chicago"
          >
            <img src={NguyenChicago} alt="Nguyen in Chicago" />
          </div>
          <div
            className="example nguyen-portland"
            // onClick={() => handleImageClick(NguyenPortland)}
            // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(NguyenPortland)}
            // role="button"
            // tabIndex={0}
            aria-label="View Nguyen in Portland, ME"
          >
            <img src={NguyenPortland} alt="Nguyen in Portland, ME" />
          </div>
        </div>
      </div>
      <div className="background-media">
        <div className="circle-burst green one">
          <img src={circleGreen} className="circle" alt="circle green" />
          <img src={burstGreen} className="burst" alt="burst green" />
        </div>
        <div className="circle-burst yellow one">
          <img src={circleYellow} className="circle" alt="circle yellow" />
          <img src={burstYellow} className="burst" alt="burst yellow" />
        </div>
      </div>

      {lightboxImage && (
        <div
          className="lightbox"
          onClick={handleCloseLightbox}
          onKeyDown={(e) => e.key === 'Escape' && handleCloseLightbox()}
          role="button"
          tabIndex={0}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: `rgba(0, 0, 0, ${isLightboxVisible ? '0.8' : '0'})`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            cursor: 'pointer',
            opacity: isLightboxVisible ? 1 : 0,
            transition: 'opacity 0.3s ease-in-out, background-color 0.3s ease-in-out'
          }}
        >
          <button
            onClick={handleCloseLightbox}
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              background: 'none',
              border: 'none',
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              padding: '10px',
              zIndex: 1001,
              opacity: isLightboxVisible ? 1 : 0,
              transform: `translateY(${isLightboxVisible ? '0' : '-20px'})`,
              transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out'
            }}
            aria-label="Close lightbox"
          >
            ✕
          </button>
          <img
            src={lightboxImage}
            alt="Enlarged view"
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              objectFit: 'contain',
              opacity: isLightboxVisible ? 1 : 0,
              transform: `scale(${isLightboxVisible ? '1' : '0.95'})`,
              transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out'
            }}
          />
        </div>
      )}
    </section>
  );
};
