import React, { useEffect, useRef, useState } from 'react';
import './LifeChangingExperiences.scss';

import starRed from './../../assets/star-red.png';
import starYellow from './../../assets/star-yellow.png';
import starGreen from './../../assets/star-green.png';
import starBlue from './../../assets/star-blue.png';
import circleGreen from './../../assets/circle-green.png';
import burstGreen from './../../assets/burst-green.png';

import explorationCara from './../../assets/exploration-cara.png';
import longhorn100 from './../../assets/longhorn-100.png';
import masonParent from './../../assets/mason-parent.png';
import hannaInternational from './../../assets/hanna-international.png';
import jillImprov from './../../assets/jill-improv.png';
import markAnniversary from './../../assets/mark-anniversary.png';
import nguyenMarket from './../../assets/nguyen-market.png';
import annieStingrays from './../../assets/annie-stingrays.png';
import markMilam from './../../assets/mark-milam.png';
import caraParibasOpen from './../../assets/cara-paribas-open.png';

export default function LifeChangingExperiences() {
  const [lightboxImage, setLightboxImage] = useState(null);
  const [isLightboxVisible, setIsLightboxVisible] = useState(false);


  const handleImageClick = (imageSrc) => {
    setLightboxImage(imageSrc);
    setTimeout(() => setIsLightboxVisible(true), 50);
  };

  const handleCloseLightbox = () => {
    setIsLightboxVisible(false);
    setTimeout(() => setLightboxImage(null), 300);
  };

  return (
    <section className="life-changing-experiences">
      <div className="container">
        <div className="description">
          <h2>
            {/* <img src={starYellow} className="star yellow" alt="star yellow" /> */}
            …and had life-changing experiences.
          </h2>

        </div>
        <div className="featured-media">
            <img
              src={longhorn100}
              className="longhorn-100"
              alt={`3RC named in the Longhorn 100`}
              // onClick={() => handleImageClick(longhorn100)}
              // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(longhorn100)}
              // role="button"
              // tabIndex={0}
          />
          <img
              src={masonParent}
              className="mason-parent"
              alt={`Mason became a parent`}
              // onClick={() => handleImageClick(masonParent)}
              // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(masonParent)}
              // role="button"
              // tabIndex={0}
          />
          <img
              src={hannaInternational}
              className="hanna-international"
              alt={`Hanna's first international trip`}
              // onClick={() => handleImageClick(hannaInternational)}
              // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(hannaInternational)}
              // role="button"
              // tabIndex={0}
          />
          <img
              src={jillImprov}
              className="jill-improv"
              alt={`Jill started improv classes`}
              // onClick={() => handleImageClick(jillImprov)}
              // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(jillImprov)}
              // role="button"
              // tabIndex={0}
          />
          <img
              src={markAnniversary}
              className="mark-anniversary"
              alt={`Mark and Anissa's 26th anniversary`}
              // onClick={() => handleImageClick(markAnniversary)}
              // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(markAnniversary)}
              // role="button"
              // tabIndex={0}
          />
          <img
              src={nguyenMarket}
              className="nguyen-market"
              alt={`Nguyen's first zine market`}
              // onClick={() => handleImageClick(nguyenMarket)}
              // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(nguyenMarket)}
              // role="button"
              // tabIndex={0}
          />
          <img
              src={annieStingrays}
              className="annie-stingrays"
              alt={`Annie swam with stingrays`}
              // onClick={() => handleImageClick(annieStingrays)}
              // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(annieStingrays)}
              // role="button"
              // tabIndex={0}
          />
          <img
              src={markMilam}
              className="mark-milam"
              alt={`Mark sent Milam off to college`}
              // onClick={() => handleImageClick(markMilam)}
              // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(markMilam)}
              // role="button"
              // tabIndex={0}
          />
          <img
              src={caraParibasOpen}
              className="cara-paribas-open"
              alt={`Cara went to the Paribas Open`}
              // onClick={() => handleImageClick(caraParibasOpen)}
              // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(caraParibasOpen)}
              // role="button"
              // tabIndex={0}
            />
        </div>
      </div>
      <div className="background-media">
        <img src={starBlue} className="star blue" alt="star blue" />
        <img src={starRed} className="star red" alt="star red" />
        <div className="circle-burst green">
          <img src={circleGreen} className="circle green" alt="circle green" />
          <img src={burstGreen} className="burst green" alt="burst green" />
        </div>
      </div>
      <div
        className={`lightbox ${isLightboxVisible ? 'visible' : ''}`}
        onClick={handleCloseLightbox}
        onKeyDown={(e) => e.key === 'Escape' && handleCloseLightbox()}
        role="button"
        tabIndex={0}
      >
        <button
          className="close-button"
          onClick={handleCloseLightbox}
          aria-label="Close lightbox"
        >
          ×
        </button>
        {lightboxImage && <img src={lightboxImage} alt="Enlarged view" />}
      </div>
    </section>
  );
};
