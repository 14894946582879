import React from 'react';
import { Helmet } from 'react-helmet';
import './Intro.scss';

import giftTag from './../../assets/3rc-gift-tag-5.png';
import wrappingPaper from './../../assets/wrapping-paper-1.png';


export default function Intro() {
  return (
    <section className="intro-2024" style={{ backgroundImage: `url(${wrappingPaper})` }}>
      <img src={giftTag} className="gift-tag" alt="gift tag" />
      <div className="background-media">
      </div>
    </section>
  );
};
