// import firebase from 'firebase';
// import { initializeApp } from "firebase/app";
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAtgkCmp9hmos2jJqj9kqbQFo6eBkWrOMw",
  authDomain: "third-rail-creative.firebaseapp.com",
  projectId: "third-rail-creative",
  storageBucket: "third-rail-creative.appspot.com",
  messagingSenderId: "627338785318",
  appId: "1:627338785318:web:62306c334dad5efc416ce7"
};


if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();
export { firestore };
