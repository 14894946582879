import React, { useEffect, useRef, useState } from 'react';
import './NewSkills.scss';

import starRed from './../../assets/star-red.png';
import starBlue from './../../assets/star-blue.png';
import starYellow from './../../assets/star-yellow.png';
import flowerRed from './../../assets/flower-red.png';

import placeholder from './../../assets/placeholder.png';
import hobbiesAnnie from './../../assets/hobbies-annie.png';
import hobbiesNguyen from './../../assets/hobbies-nguyen.png';
import hobbiesBud from './../../assets/hobbies-bud.png';
import hobbiesLauren from './../../assets/hobbies-lauren.png';
import hobbiesBryan from './../../assets/hobbies-bryan.png';
import hobbiesJill from './../../assets/hobbies-jill.png';
import hobbiesMason from './../../assets/hobbies-mason.png';
import hobbiesMark from './../../assets/hobbies-mark.png';


export default function NewSkills() {
  const examplesRef = useRef(null);
  const [messageCount, setMessageCount] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const [projectCount, setProjectCount] = useState(0);
  const [lightboxImage, setLightboxImage] = useState(null);
  const [isLightboxVisible, setIsLightboxVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target.classList.contains('examples')) {
            // Start counting animations when examples section is visible
            const animateCount = (start, end, setter, duration) => {
              const startTime = Date.now();
              const updateCount = () => {
                const now = Date.now();
                const progress = Math.min((now - startTime) / duration, 1);
                const currentCount = Math.floor(progress * (end - start) + start);
                setter(currentCount);
                if (progress < 1) {
                  requestAnimationFrame(updateCount);
                }
              };
              requestAnimationFrame(updateCount);
            };

            animateCount(0, 108665, setMessageCount, 2000);
            animateCount(0, 7861, setFileCount, 2000);
            animateCount(0, 158, setProjectCount, 2000);
          } else {
            entry.target.style.transform = 'translateY(-15px)';
            entry.target.style.opacity = '1';
          }
        }
      });
    }, { threshold: 0.1 });

    const descriptionChildren = document.querySelectorAll('.description > *');
    const exampleItems = document.querySelectorAll('.example');

    [...descriptionChildren, ...exampleItems, examplesRef.current].forEach(el => {
      el.style.transition = 'transform 0.8s ease-out, opacity 0.8s ease-out';
      el.style.transform = 'translateY(-25px)';
      el.style.opacity = '1';
      observer.observe(el);
    });
  }, []);

  const handleImageClick = (imageSrc) => {
    setLightboxImage(imageSrc);
    setTimeout(() => setIsLightboxVisible(true), 50);
  };

  const handleCloseLightbox = () => {
    setIsLightboxVisible(false);
    setTimeout(() => setLightboxImage(null), 300);
  };

  return (
    <section className="new-skills">
      <div className="container">
        <div className="description">
          <h2>
            <img src={starBlue} className="star blue" alt="star blue" />
            ...flexed new skills...
          </h2>
          <p>
            New passions kept our creativity flowing all year long.
          </p>
        </div>
        <div className="examples" ref={examplesRef}>
          <div className="example annie">
            <div
              className="img-container"
              // onClick={() => handleImageClick(hobbiesAnnie)}
              // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(hobbiesAnnie)}
              // role="button"
              // tabIndex={0}
              aria-label="View Annie's needlepoint image"
            >
              <img src={hobbiesAnnie} alt="Annie's needlepoint" />
            </div>
            <p>Annie found relaxation in the intricate art of needlepoint.</p>
          </div>
          <div className="example bud">
            <div
              className="img-container"
              // onClick={() => handleImageClick(hobbiesBud)}
              // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(hobbiesBud)}
              // role="button"
              // tabIndex={0}
              aria-label="View Bud's photography image"
            >
              <img src={hobbiesBud} alt="Bud's photography" />
            </div>
            <p>Bud took his iPhone photography up a notch.</p>
          </div>
          <div className="example lauren">
            <div
              className="img-container"
              // onClick={() => handleImageClick(hobbiesLauren)}
              // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(hobbiesLauren)}
              // role="button"
              // tabIndex={0}
              aria-label="View Lauren's ballet image"
            >
              <img src={hobbiesLauren} alt="Lauren's ballet" />
            </div>
            <p>Lauren danced her way through ballet classes.</p>
          </div>
          <div className="example bryan">
            <div
              className="img-container"
              // onClick={() => handleImageClick(hobbiesBryan)}
              // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(hobbiesBryan)}
              // role="button"
              // tabIndex={0}
              aria-label="View Bryan's Land Cruiser image"
            >
              <img src={hobbiesBryan} alt="Bryan's Land Cruiser" />
            </div>
            <p>Bryan began restoring his first car, a 1971 Toyota Land Cruiser.</p>
          </div>
          <div className="example jill">
            <div
              className="img-container"
              // onClick={() => handleImageClick(hobbiesJill)}
              // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(hobbiesJill)}
              // role="button"
              // tabIndex={0}
              aria-label="View Jill's Book Nooks image"
            >
              <img src={hobbiesJill} alt="Jill's Book Nooks" />
            </div>
            <p>Jill explored new worlds through handcrafted Book Nooks.</p>
          </div>
          <div className="example mason">
            <div
              className="img-container"
              // onClick={() => handleImageClick(hobbiesMason)}
              // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(hobbiesMason)}
              // role="button"
              // tabIndex={0}
              aria-label="View Mason's drum image"
            >
              <img src={hobbiesMason} alt="Mason's drum" />
            </div>
            <p>Mason got hands-on and rhythmic, crafting a drum by hand.</p>
          </div>
          <div className="example mark">
            <div
              className="img-container"
              // onClick={() => handleImageClick(hobbiesMark)}
              // onKeyDown={(e) => e.key === 'Enter' && handleImageClick(hobbiesMark)}
              // role="button"
              // tabIndex={0}
              aria-label="View Mark's pickleball image"
            >
              <img src={hobbiesMark} alt="Mark's pickleball" />
            </div>
            <p>Mark took his pickleball game to new heights, competing in tournaments and falling deeper in love with the sport.</p>
          </div>
        </div>
      </div>
      <div className="background-media">
        <img src={flowerRed} className="flower red" alt="flower red" />
        <img src={starYellow} className="star yellow" alt="star yellow" />
      </div>
      {lightboxImage && (
        <div
          className={`lightbox ${isLightboxVisible ? 'visible' : ''}`}
          onClick={handleCloseLightbox}
          onKeyDown={(e) => e.key === 'Escape' && handleCloseLightbox()}
          role="button"
          tabIndex={0}
        >
          <img src={lightboxImage} alt="Lightbox" />
        </div>
      )}
    </section>
  );
};
